// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';


require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('jquery-ui')

$(document).on('ready', function() {
  $( ".sortable" ).sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "patch",
        data: $(this).sortable('serialize'),
        success: function(data) {},
        error: function(data) {}
      })
    }
  });  
});

require("trix")
require("@rails/actiontext")